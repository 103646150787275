<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/TotalTaskManage' }"
      >返回</el-breadcrumb-item
    >
    <el-breadcrumb-item></el-breadcrumb-item>
  </el-breadcrumb>
  <br />
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div>
        <span style="width: 180px">任务名称:</span>
        <el-input
          v-model="option.taskName"
          clearable
          placeholder="搜索任务名称"
        />
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary"
          >查 询</el-button
        >
        <el-button
          icon="el-icon-document-add"
          @click="AddClick()"
          type="primary"
          >新增任务</el-button
        >
      </div>
    </div>

    <!--2. table表格的内容 data.table[option.page] -->
    <el-table border stripe :data="data.table" style="width: 100%">
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column>
      <!--隐藏列-->
      <el-table-column
        type="index"
        :index="indexMethod"
        width="50"
        style="text-align: center"
      ></el-table-column>
      <el-table-column prop="taskName" label="任务名称"> </el-table-column>
      <el-table-column prop="taskLocation" label="任务位置">
        <template v-slot="scope">
          <span v-if="scope.row.taskLocation == 1">任务一</span>
          <span v-else-if="scope.row.taskLocation == 2">任务二</span>
          <span v-else>任务三</span>
        </template>
      </el-table-column>
      <!--可以格式化-->
      <el-table-column
        label="图标"
        prop="imageUrl"
        align="center"
        width="100px"
      >
        <template v-slot="scope">
          <el-image
            @click="bigImg(scope.row.imageUrl)"
            style="width: 50px; height: 50px; margin-bottom: -4px"
            :src="scope.row.imageUrl"
            :preview-src-list="data.srcList"
          >
          </el-image>
          <el-popover placement="top-start" trigger="click">
            <!--trigger属性值：hover、click、focus 和 manual-->
            <a
              :href="scope.row.imageUrl"
              target="_blank"
              title="查看最大化图片"
            >
              <img
                :src="scope.row.imageUrl"
                style="width: 300px; height: 300px"
              />
            </a>
            <img
              slot="reference"
              :src="scope.row.imageUrl"
              style="width: 50px; height: 50px; cursor: pointer"
            />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="isActive" label="状态"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="300">
        <!--操作固定列 -->
        <template #default="scope">
          <el-button @click="updateClick(scope.row)" type="primary" size="small"
            >编辑</el-button
          >
          <el-button
            v-if="scope.row.isActive == '启用'"
            size="mini"
            type="danger"
            @click="handleActive(scope.$index, scope.row)"
            >禁用</el-button
          >
          <el-button
            v-else
            size="mini"
            type="primary"
            @click="handleActive(scope.$index, scope.row)"
            >启用</el-button
          >
          <el-button
            @click="viewCollectionDetail(scope.row)"
            type="primary"
            size="small"
            >领取记录</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="10"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>

    <!-- 4.更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog
      width="52%"
      title="任务管理信息"
      v-model="ruleForm.dialogFormVisible"
      :show-close="true"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="任务名称" required>
          <el-col :span="11">
            <el-form-item prop="taskName">
              <el-input
                v-model="ruleForm.taskName"
                clearable
                placeholder="任务名称"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="任务位置" required>
          <el-col :span="11">
            <el-form-item prop="taskLocation">
              <el-select
                v-model="ruleForm.taskLocation"
                clearable
                placeholder="请选择位置"
              >
                <!--                @change="getTabList"-->
                <el-option
                  v-for="item in data.locationList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="所属任务组" required>
          <el-col :span="8">
            <el-form-item prop="totalTaskName">
              <el-select
                v-model="ruleForm.totalTaskName"
                clearable
                placeholder="请选择任务组"
              >
                <el-option
                  v-for="item in data.totalTaskList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="上传图标">
          <el-upload
            ref="myupload"
            action=""
            :file-list="ruleForm.fileList"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            @on-change="handleChange"
            :beforeUpload="beforeUpload"
            :http-request="handleUpload"
            :multiple="false"
            :limit="1"
            accept=".png,.jpg"
          >
            <el-button size="small" type="primary">上传图片</el-button>
          </el-upload>
          <el-col>
            <p style="color: red; padding-bottom: 20px">
              * 图片格式 宽：184px；高：184px；大小不超过
              2MB；扩展名：".png,.jpg,.jpeg"
            </p>
          </el-col>
          <div v-if="ruleForm.imageDiv">
            <el-image
              style="width: 20%; height: 20%"
              :src="ruleForm.imageUrl"
              :fit="fit"
            ></el-image>
            <el-button
              @click="imageClose"
              style="position: absolute; margin-left: -40px"
              type="danger"
              icon="el-icon-close"
              circle
            ></el-button>
          </div>
        </el-form-item>
        <el-form-item label="消费金额">
          <el-col :span="8">
            <el-form-item prop="amount">
              <el-input-number
                v-model="ruleForm.amount"
                :min="0"
                :controls="false"
                clearable
                placeholder="消费金额"
              />
            </el-form-item>
          </el-col>
          <el-col class="line" :span="4">
            <el-form-item prop="ruleConditions" style="margin-left: 5px">
              <el-select
                v-model="ruleForm.ruleConditions"
                clearable
                placeholder="规则设置"
                style="width: 120px"
              >
                <el-option
                  v-for="item in data.ruleList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="8">
            &nbsp;&nbsp;&nbsp;&nbsp;到店次数&nbsp;&nbsp;&nbsp;</el-col
          >
          <el-col :span="8">
            <el-form-item prop="count">
              <el-input-number
                :min="0"
                :controls="false"
                v-model="ruleForm.count"
                clearable
                placeholder="到店次数"
              />
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="奖品积分">
          <el-col :span="8">
            <el-form-item prop="points">
              <el-input
                type="number"
                min="0"
                oninput="value=value.repalce(/[^0-9]/g,'')"
                v-model="ruleForm.points"
                clearable
                placeholder="奖品积分"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="领取方式">
          <el-col :span="8">
            <el-form-item prop="type">
              <el-select
                v-model="ruleForm.type"
                placeholder="请选择优惠券领取方式"
                @change="() => (data.couponsList = [])"
              >
                <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="优惠券">
          <el-col :span="8">
            <el-form-item prop="coupons">
              <el-select
                v-model="ruleForm.couponsList"
                multiple
                filterable
                clearable
                placeholder="请选择优惠券"
                @click="getCouponsListClick"
              >
                <el-option
                  v-for="item in data.couponsList"
                  :key="item.coupon_id"
                  :label="item.coupon_name"
                  :value="item.coupon_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
import { reactive, ref, onMounted } from "vue";
import {
  getTaskList,
  updateTask,
  addTask,
  getTotalTasklist,
  getCouponsList,
  getTaskById,
  delBanner,
  updateTaskActive,
} from "../http/api";
import { ElMessage } from "element-plus";
import { putToOSS, getFileNameUUID, getFileExtensions } from "@/http/oss";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "TaskManage",
  setup() {
    // 领取方式选项
    const typeOptions = [
      {
        label: "主动领取",
        value: 10,
      },
      {
        label: "直接发放",
        value: 40,
      },
    ];
    // 默认的领取方式
    const defaultType = typeOptions[0];

    const ruleFormRef = ref(null); //表单不能用this.$,取一个重名的
    const route = useRoute();
    let pointstotalId = route.query.id;
    let propTaskStartDate = route.query.taskStartDate;
    let propTaskEndDate = route.query.taskEndDate;

    onMounted(async () => {
      // 在onMounted生命周期钩子中调用GetAllListAsync方法
      if (!pointstotalId) {
        router.push({ name: "TotalTaskManage" });
      }
    });
    //删除事件
    let deleteClick = async (obj) => {
      //console.log(obj.id);
      let data = { id: obj.id };
      var delResult = await delBanner(data);
      //console.log(delResult);
      queryTaskListFun(option);
      if (delResult) {
        ElMessage.success({
          message: "删除成功！",
          duration: 2000,
          type: "success",
        });
      } else {
        ElMessage.error({
          message: "有相关联的订单，删除失败！",
          duration: 2000,
        });
      }
    };
    const router = useRouter();
    let viewCollectionDetail = async (obj) => {
      router.push({ name: "TaskCollectionDetails", query: { id: obj.id } });
    };
    let handleActive = async (index, obj) => {
      ruleForm.addOrUpdate = 1;
      let isActiveFlag;
      if (obj.isActive == "启用") {
        isActiveFlag = false;
      } else {
        isActiveFlag = true;
      }
      let data = {
        id: obj.id,
        isActive: isActiveFlag,
      };
      await updateTaskActive(data);
      queryTaskListFun(option);
      ruleFormRef.value.resetFields();
    };
    //删除上传的图片
    let imageClose = () => {
      ruleForm.imageUrl = "";
      ruleForm.imageDiv = false;
    };
    let getCouponsListClick = async () => {
      let params = {
        taskStartDate: propTaskStartDate,
        taskEndDate: propTaskEndDate,
        type: ruleForm.type, // 优惠券领取方式
      };
      data.couponsList = await getCouponsList(params);
      console.log("获取优惠券。。。。", data.couponsList);
    };
    //修改的表单参数//////////////////////////////////////////////////
    let ruleForm = reactive({
      dialogFormVisible: false,
      id: "",
      bannerTitle: "",
      DisplayDate: "",
      cityId: "",
      shopId: "",
      fileList: [],
      bannerSort: "0",
      bannerMemo: "",
      addOrUpdate: 0, //0新增，1更新
      imageDiv: false,
      imageUrl: "",
      type: defaultType.value, // 优惠券领取方式
    });

    //点击新增按钮
    let AddClick = async () => {
      ruleForm.addOrUpdate = 0;
      ruleForm.dialogFormVisible = true; //显示出表单
      data.totalTaskList = await getTotalTasklist();
      ruleFormRef.value.resetFields();
      ruleForm.taskName = "";
      ruleForm.taskLocation = "";
      ruleForm.ruleConditions = 1;
      ruleForm.isActive = true;
      ruleForm.count = "";
      ruleForm.amount = "";
      ruleForm.points = "";
      ruleForm.coupons = "";
      ruleForm.imageUrl = "";
      ruleForm.type = defaultType.value; // 优惠券领取方式
    };

    //点击修改按钮,弹框，默认把原来的数据显示出来
    let updateClick = async (obj) => {
      ruleForm.addOrUpdate = 1;
      ruleForm.id = obj.id;
      ruleForm.couponsList = [];

      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let tasks = await getTaskById({ id: obj.id });
      ruleForm.dialogFormVisible = true; //显示出表单
      // 获取任务组
      var list = await getTotalTasklist();
      data.totalTaskList = list;
      ruleForm.taskName = tasks.taskName;
      ruleForm.totalTaskName = tasks.totalTaskName;
      ruleForm.taskLocation = tasks.taskLocation;
      ruleForm.ruleConditions = tasks.ruleConditions;
      ruleForm.count = tasks.count;
      ruleForm.amount = tasks.amount;
      ruleForm.points = tasks.points;
      ruleForm.imageUrl = tasks.imageUrl;
      ruleForm.imageDiv = true;

      await getCouponsListClick();
      if (tasks.coupons != null && tasks.coupons != "") {
        let couponsListStr = tasks.coupons.split(",");
        let arr = [];
        for (var i = 0; i < couponsListStr.length; i++) {
          arr.push(parseInt(couponsListStr[i]));
          if (arr.length > 0) {
            ruleForm.couponsList = arr;
          }
        }
      }
    };

    //修改的表单验证
    let rules = reactive({
      taskName: [{ required: true, message: "请输入任务名称" }],
      totalTaskName: [{ required: true, message: "请选择任务组名称" }],
      taskLocation: [{ required: true, message: "请选择任务位置" }],
      imageUrl: [{ required: true, message: "请上传图标" }],
    });

    // ruleForm.ruleConditions = tasks.ruleConditions;
    // ruleForm.count = tasks.count;
    // ruleForm.amount = tasks.amount;
    // ruleForm.points = tasks.points;
    // ruleForm.coupons = tasks.coupons;
    // ruleForm.imageUrl = tasks.imageUrl;

    //提交表单,修改信息
    let submitForm = async () => {
      let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          //console.log("下面的不执行了");
          return false;
        }
      });
      if (con) {
        return false;
      }
      //更新并且重新加载数据
      if (ruleForm.addOrUpdate == 1) {
        //更新
        let data = {
          id: ruleForm.id,
          taskName: ruleForm.taskName,
          totalTaskName: ruleForm.totalTaskName,
          taskLocation: ruleForm.taskLocation,
          ruleConditions: ruleForm.ruleConditions,
          count: ruleForm.count,
          amount: ruleForm.amount,
          points: ruleForm.points,
          imageUrl: ruleForm.imageUrl,
          isActive: true,
          coupons: ruleForm.couponsList.toString(),
        };

        await updateTask(data);
      } else {
        //新增
        let data = {
          taskName: ruleForm.taskName,
          totalTaskName: ruleForm.totalTaskName,
          taskLocation: ruleForm.taskLocation,
          ruleConditions: ruleForm.ruleConditions,
          count: ruleForm.count || 0,
          amount: ruleForm.amount || 0,
          points: ruleForm.points || 0,
          imageUrl: ruleForm.imageUrl,
          coupons: ruleForm.couponsList.toString(),
          isActive: true,
        };
        await addTask(data);
        ruleForm.imageUrl = "";
        ruleForm.imageDiv = false;
        ruleForm.fileList = [];
      }
      queryTaskListFun(option);
      ruleFormRef.value.resetFields();
      //setTimeout(function () {
      ruleForm.dialogFormVisible = false;
      //}, 500);
    };

    //表单重置
    let resetForm = (formName) => {
      ///////ruleFormRef.value.resetFields();

      ruleForm.dialogFormVisible = false;
    };

    //声明数据
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      shopListData: [],
      totalTaskList: [],
      ruleList: [
        {
          id: 1,
          value: "或满足",
        },
        {
          id: 2,
          value: "同时满足",
        },
      ],
      cityListData: [],
      locationList: [
        {
          id: 1,
          value: "任务一",
        },
        {
          id: 2,
          value: "任务二",
        },
        {
          id: 3,
          value: "任务三",
        },
      ],
      couponsList: [],
      srcList: [""],
    });

    //声明查询条件
    let option = reactive({
      pointsId: pointstotalId,
      taskName: "",
      page: 1,
      pageSize: 10,
    });

    let indexMethod = (index) => {
      return index + (option.page - 1) * option.pageSize + 1;
    };

    //大图
    let bigImg = (url) => {
      data.srcList[0] = url;
    };
    //获取任务列表方法
    let queryTaskListFun = async (obj) => {
      let arr = await getTaskList(obj);
      data.total = arr.totalCount;
      //格式化日期
      arr.items.filter((item) => {
        item.isActive = item.isActive == true ? "启用" : "禁用";
      });
      data.table = arr.items;
    };

    //初始化时，获取初始化数据
    queryTaskListFun(option);

    //点击页码获取数据
    let handleCurrentChange = (index) => {
      //接口是接收的guid,这样写不报错
      if (option.taskName == "") {
        option.taskName = null;
      }
      option.page = index;
      queryTaskListFun(option);
    };

    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      queryTaskListFun(option);
    };

    let beforeUpload = (file) => {
      // 限制上传类型
      const fileExtensions =
        getFileExtensions(file.name) === ".jpg" ||
        getFileExtensions(file.name) === ".png" ||
        getFileExtensions(file.name) === ".jpeg";
      //限制的上限为2M
      const max2M = file.size / 1024 / 1024 < 2;
      if (!fileExtensions) {
        ElMessage.error({
          message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
          duration: 2000,
        });
      }
      if (!max2M) {
        ElMessage.error({
          message: "上传文件大小不能超过 2MB!",
          duration: 2000,
        });
      }
      return fileExtensions && max2M;
    };
    let handleUpload = (option) => {
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName =
        "ShopBannerImg/" +
        getFileNameUUID() +
        getFileExtensions(option.file.name);
      // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
      putToOSS(`flieName/${objName}`, option.file).then((res) => {
        //console.log(res,'res')
        // 上传成功之后，转换真实的地址
        //signatureUrl(`flieName/${objName}`).then(res => {
        //console.log(res)
        ruleForm.imageUrl = res.url;
        ruleForm.imageDiv = true;
        //})
      });
    };
    let submitUpload = () => {
      this.$refs.upload.submit();
    };
    let handleRemove = (file, fileList) => {
      console.log(file, fileList);
    };
    let handlePreview = (file) => {
      console.log(file);
    };
    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      updateClick,
      ruleForm,
      ruleFormRef,
      rules,
      submitForm,
      resetForm,
      AddClick,
      deleteClick,
      imageClose,
      getFileExtensions,
      beforeUpload,
      handleUpload,
      submitUpload,
      handleRemove,
      handlePreview,
      bigImg,
      getCouponsListClick,
      viewCollectionDetail,
      handleActive,
      indexMethod,
      typeOptions,
      defaultType,
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.box {
}
.el-image-viewer__close {
  top: 40px;
  right: 40px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0) !important;
}
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 15%;
    display: flex;
    align-items: center;
    span {
      width: 50px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
